import CrudStore from '~/store/modules/common/crudStore'
var crud = new CrudStore('fileManager', null, true)

export const state = () => ({
  ...crud.state,
  currentPath: '',
  // document structure cache
  documentMap: {},
  searchTerms: '',
  openFolder: '',
})

export const getters = {
  ...crud.getters,
  getDocuments(state) {
    return state.documentMap
  },
  getPath: (state) => (document) => {
    return getLodashPath(typeof document === 'object' && 'path' in document ? document.path : document)
  },
  getLabel: (state) => (id) => {},
  getMetadata: (state) => (obj) => {
    const userMetadata = {}
    if (obj) {
      Object.entries(obj).forEach(([metaKey, metaVal]) => {
        if (typeof metaVal !== 'string') {
          userMetadata[metaKey] = metaVal
          return
        }

        if (metaKey === 'permissions') metaVal = metaVal.toLowerCase()
        if (metaVal === 'null' || !metaVal) userMetadata[metaKey] = null

        if (metaVal.startsWith('[')) {
          userMetadata[metaKey] = JSON.parse(metaVal) || []
        } else if (metaVal.startsWith('{')) {
          userMetadata[metaKey] = JSON.parse(metaVal) || {}
        } else {
          userMetadata[metaKey] = metaVal
        }
      })
    }

    return userMetadata
  },
  getAllFiles: (state, getters, rootState, rootGetters) => {
    if (rootState.auxiliary.documents.objectsPending) return []
    let objectArray = []

    const docs = _.cloneDeep(_.get(rootState, 'auxiliary.documents.documents', []))
    docs.forEach((doc) => {
      let path = doc.path.split('/')
      path = path.filter((el, i) => i > 1).join('/')

      _.set(doc, 'metadata.permissions', _.get(doc, 'metadata.permissions', '').toLowerCase())

      objectArray.push({
        ...doc,
        ...doc.metadata,
        lastModified: doc.dateLastModified,
        path,
      })
    })

    return objectArray
  },
  getFileView: (state, getters, rootState, rootGetters) => {
    // Construct _documents object with each key as file/folder name
    if (rootState.auxiliary.documents.objectsPending) return []
    let objectArray = []
    const currentPath = _.cloneDeep(state.currentPath)

    const view = currentPath ? _.get(state.documentMap, getLodashPath(currentPath)) : state.documentMap
    if (!view) return []

    Object.entries(view).map(([k, v]) => {
      if (k === '_metadata') return
      let originalFileName = k.replace('/', '')

      const metadata = v?._metadata ? v._metadata : { type: 'folder', path: '', size: '', lastModified: '', permissions: '', fileName: '', dateUploaded: '' }

      let resolvedFileName = $nuxt.$utils.getDisplayName(originalFileName)
      let fileName = resolvedFileName !== 'Unnamed' ? resolvedFileName : originalFileName.replace(`.${metadata.type}`, '')

      const userMetadata = getters.getMetadata(metadata?.object)

      // match to documents by the path
      const correspondingDocument = rootGetters['auxiliary/documents/getByPath'](metadata.fullPath)
      const isFolder = v?._metadata?.type === 'folder'

      // Could not find a corresponding document for the s3 file
      if (!isFolder && !correspondingDocument) return

      if (fileName) {
        objectArray.push({
          type: metadata.type,
          path: metadata.path,
          size: metadata.size,
          lastModified: metadata.lastModified,
          dateUploaded: correspondingDocument?.dateUploaded || '',
          fileName,
          id: correspondingDocument?.id ?? $nuxt.$uuid(),
          metadata: userMetadata,
          ...userMetadata,
        })
      }
    })
    return objectArray
  },
  searchFiles: (state, getters, rootState, rootGetters) => {
    if (Array.isArray(rootState.auxiliary.documents.documents) && rootState.auxiliary.documents.documents.length === 0) return []

    return rootState.auxiliary.documents.documents
      .map((document) => {
        let userMetadata = getters.getMetadata(document.metadata)
        const fileName = $nuxt.$utils.isUUID(document.fileName.replace('/', '')) ? $nuxt.$utils.getDisplayName(document.fileName.replace('/', '')) : document.type === 'folder' ? document.fileName.replace('/', '') : document.fileName.replace(`.${document.type}`, '')

        return {
          ...document,
          ...userMetadata,
          fileName,
          metadata: userMetadata,
          lastModified: document.dateLastModified,
          dateUploaded: document.dateUploaded,
          path: document.path.split(document.entityGroupId + '/')[1],
        }
      })
      .filter((document) => {
        // Result array cannot contain false
        const resultArray = []

        for (let i = 0; i < state.searchTerms.trim().split(' ').length; i++) {
          let term = state.searchTerms.trim().split(' ')[i].trim().toLowerCase()

          if (document.fileName.toLowerCase().includes(term)) resultArray[i] = true

          // Search among tags, entities
          if (document.tags) {
            for (let tag of document.tags) {
              tag = rootGetters['modules/configurationHub/getTag'](tag)
              if (tag?.name?.toLowerCase()?.includes(term)) resultArray[i] = true
            }
          }
          if (document.entities) {
            for (let entityId of document.entities) {
              const entityName = $nuxt.$utils.getDisplayName(entityId)
              if (entityName.toLowerCase().includes(term)) resultArray[i] = true
            }
          }

          // If no matches were found for this term, add false
          if (resultArray[i] !== true) {
            resultArray[i] = false
          }
        }

        return !resultArray.includes(false)
      })
  },
}

export const mutations = {
  ...crud.mutations,
  setOpenFolder(state, folder) {
    state.openFolder = folder
  },
  setCurrentPath(state, payload) {
    state.currentPath = payload.replace(/^\.[\w\d-]+\//g, '')
  },
  setSearchTerms(state, payload) {
    state.searchTerms = payload
  },
  refreshDocumentMap(state, payload) {
    // Refresh on root folder
    if (!payload) state.documentMap = {}
    else {
      // Refresh a subdirectory
      _.set(state.documentMap, getLodashPath(payload), { _metadata: _.get(state.documentMap, getLodashPath(payload))?._metadata || null })
    }
  },
  setDocumentMap(state, { path, value }) {
    path = getLodashPath(path)
    let map = _.cloneDeep(state.documentMap)
    const currentState = _.cloneDeep(state.documentMap)
    let pathsSplitByDepth = path.split(new RegExp('/[.[]'))

    // Add the bracket ([) to the start of each string as we just split by it
    pathsSplitByDepth = pathsSplitByDepth.map((_path) => {
      if (_path.endsWith(']') && !path.startsWith('[')) return '[' + _path
      else return _path
    })

    let joinedPaths = ''
    let joinedPathsReal = ''
    let parentPermissions = null

    // Fill out a default _metadata key for the directories which have not been initialized
    if (pathsSplitByDepth.length > 1) {
      for (let i = 0; i < pathsSplitByDepth.length; i++) {
        const thisFolder = pathsSplitByDepth[i].replace('/', '')

        const parentDirectory = _.get(map, joinedPaths, null)
        const needsRefresh = _.get(currentState, joinedPaths, null) === null

        joinedPaths += (joinedPaths && !thisFolder.startsWith('[') ? '.' : '') + thisFolder + '/'
        // Do I need this? who knows
        // joinedPathsReal += thisFolder.startsWith('[') ? thisFolder.substring(2, thisFolder.length - 2) : thisFolder + '/'
        joinedPathsReal += thisFolder + '/'

        const subDirectory = _.get(map, joinedPaths)

        // Get the permissions of the parent folder
        if (!parentPermissions) {
          parentPermissions = parentDirectory ? _.get(parentDirectory, '_metadata?.object?.permissions', {}) : _.get(subDirectory, '_metadata?.object?.permissions', {})
        }

        // map = _.set(map, joinedPaths + '._metadata', parentPermissions ? { type: 'folder', path: joinedPathsReal, object: { permissions: parentPermissions } } : { type: 'folder', path: joinedPathsReal })
        map = _.set(map, joinedPaths + '._metadata', { type: 'folder', path: joinedPathsReal, shouldRefresh: true })
      }
    }

    // if (parentPermissions && value?._metadata?.type === 'folder') value = { ...value, _metadata: { ...value._metadata, object: { ...value._metadata.object, permissions: parentPermissions } } }

    map = _.set(map, path, value)
    state.documentMap = map
  },
  markShouldRefresh(state, { path, value }) {
    path = getLodashPath(path)
    const existing = _.get(state.documentMap, path + '._metadata')
    state.documentMap = _.set(state.documentMap, path + '._metadata', { ...existing, shouldRefresh: value })
  },
  omitFromDocumentMap(state, path) {
    state.documentMap = _.omit(state.documentMap, path)
  },
  // Renaming and editing document metadata
  updateDocument(state, { newData, oldData }) {
    const oldPath = getLodashPath(oldData.path)

    let oldFilename = oldData.fileName
    let newFilename = newData.fileName
    // normalize old file name - it might no contain the type
    if (!oldFilename.endsWith('.' + oldData.type)) oldFilename += '.' + oldData.type
    if (!newFilename.endsWith('.' + newData.type)) newFilename += '.' + newData.type

    const newRawPath = newData.path.replace(oldFilename, newFilename)
    const newPath = getLodashPath(newRawPath)

    let newMap = _.cloneDeep(state.documentMap)
    let cachedDocument = _.cloneDeep(_.get(newMap, oldPath))
    if (!cachedDocument) return
    let hasUpdated = false

    // parse metadata
    for (const key in newData.metadata) {
      if (!newData.metadata.hasOwnProperty(key)) continue

      try {
        newData.metadata[key] = JSON.parse(newData.metadata[key])
      } catch (ignored) {}
    }

    if (!_.isEqual(newData.metadata, oldData.metadata)) {
      // Update metadata
      cachedDocument = _.set(cachedDocument, '_metadata.object', newData.metadata)
      hasUpdated = true
    }

    if (newFilename !== oldFilename) {
      // Rename occurred
      cachedDocument = _.set(cachedDocument, '_metadata.path', newRawPath)
      newMap = _.omit(newMap, oldPath)
      hasUpdated = true
    }

    if (!hasUpdated) return

    // Update the documentMap
    cachedDocument = _.set(cachedDocument, '_metadata.lastModified', new Date().toISOString())
    newMap = _.set(newMap, newPath, cachedDocument)
    state.documentMap = newMap
  },
  /*
    Transfer the document between paths in documentMap, only if there is already data in the target path
    Else do nothing
  */
  transferDocument(state, { newPath, oldPath }) {
    if (!Array.isArray(newPath)) return

    // newPath is array - [dest, fileName]
    newPath = newPath.join('')
    if (newPath === oldPath) return

    let map = _.cloneDeep(state.documentMap)
    newPath = getLodashPath(newPath)

    map = _.omit(map, getLodashPath(oldPath))

    state.documentMap = map
  },
  copyDocument(state, { newPath, newObject }) {
    if (!Array.isArray(newPath)) return

    const destination = newPath[0]
    const fileName = newPath[1]

    // newPath is array, of [path, fileName]
    newPath = newPath.join('')

    let map = _.cloneDeep(state.documentMap)
    newPath = getLodashPath(newPath)

    // Check if target directory is NOT empty in cache
    const newPathHasItems = (destination ? Object.keys(_.get(map, getLodashPath(destination), {})) : Object.keys(map)).length > 1

    if (newPathHasItems) {
      // Do local transfer
      if (!destination) {
        // destination is root folder
        map[fileName] = newObject
      } else {
        map = _.set(map, newPath, newObject)
      }
    }

    state.documentMap = map
  },
}

export const actions = {
  ...crud.actions,
  debouncedSetCurrentPath: _.debounce(
    function ({ state, commit }) {
      if (state.openFolder !== '') {
        commit('setCurrentPath', state.openFolder)
        commit('setOpenFolder', '')
      }
    },
    300,
    { trailing: true }
  ),
  update({ dispatch, getters }, { payload, callback, data }) {
    const endpoint = 'document'
    this.$bus.$emit('store-update', { store: endpoint, data: payload })
    if (callback) data !== null ? callback(res.status, data) : callback(res.status)
  },
  async getDocuments({ state, commit, dispatch, rootState }, payload) {
    const currentPath = _.clone(state.currentPath)

    if (_.get(rootState, 'auxiliary.documents.objectsPending', true)) return

    // A search is active - refresh Documents array from backend
    if (state.searchTerms) {
      await dispatch('auxiliary/documents/getDocumentsByEntityGroup', true, { root: true })
      return
    }

    // Return the document map if on root folder and if there are items in document map
    if (!payload && Object.keys(state.documentMap).length !== 0) {
      return await commit('auxiliary/documents/setObjects', { _documents: state.documentMap }, { root: true })
    }

    const existingStructure = _.get(state.documentMap, getLodashPath(currentPath))

    if (!existingStructure?._metadata?.shouldRefresh) {
      // Set the documents to the existing/cached object
      if (existingStructure && Object.keys(existingStructure).length > 1) {
        await commit('auxiliary/documents/setObjects', { _documents: existingStructure }, { root: true })
        return
      }
    } else if (currentPath) commit('markShouldRefresh', { path: currentPath, value: false })

    // Make the request (could not find path in cache)
    await dispatch('auxiliary/documents/getObjects', payload, { root: true })
    const documents = _.cloneDeep(rootState.auxiliary.documents.objects)

    // Construct the map of documents
    Object.entries(documents).forEach(([k, v]) => {
      Object.entries(v).forEach(([key, val]) => {
        commit('setDocumentMap', { path: currentPath + key, value: val })
      })
    })
    dispatch('debouncedSetCurrentPath')
  },
  async refreshFileManager({ dispatch }) {
    await dispatch('auxiliary/documents/getDocumentsByEntityGroup', null, { root: true })
    await dispatch('auxiliary/documents/getFolders', null, { root: true })
    await dispatch('refreshDocuments')
  },
  async refreshDocuments({ state, commit, dispatch }, payload) {
    const currentPath = _.clone(state.currentPath)
    if (!payload) {
      commit('refreshDocumentMap', currentPath)
      await dispatch('getDocuments', currentPath)
    } else {
      commit('refreshDocumentMap', payload)
      await dispatch('getDocuments', payload)
    }
  },
  async uploadDocument({ state, commit, dispatch }, payload) {
    const currentPath = _.clone(state.currentPath)
    if (currentPath) commit('auxiliary/documents/setPrefix', currentPath, { root: true })

    return await dispatch('auxiliary/documents/upload', payload, { root: true })
  },
  async modifyDocument({ state, commit, dispatch }, { payload, snapshot, documentId }) {
    if (!documentId) return false

    const result = await dispatch('auxiliary/documents/update', { id: documentId, payload }, { root: true })
    if (result?.status === 200) {
      const newData = result?.data?.document
      commit('updateDocument', { newData, oldData: snapshot })
      // await dispatch('getDocuments', currentPath)
      return newData
    } else {
      // Indicate to user
      return false
    }
  },
  async copyDocument({ state, commit, dispatch }, { document, path }) {
    if (!document || !document.id) return
    return await dispatch('auxiliary/documents/copy', { id: document.id, payload: { path } }, { root: true })
  },
  async transferDocument({ state, commit, dispatch }, { document, path }) {
    if (!document || !document.id) return
    const currentPath = _.clone(state.currentPath)

    const result = await dispatch('auxiliary/documents/transfer', { id: document.id, payload: { path, currentPath } }, { root: true })

    if (_.get(result, 'status', 400) === 200) {
      // We can do a local transfer to reflect the S3 folder structure
      commit('transferDocument', { newPath: [path, document.fileName + '.' + document.type], oldPath: document.path })
      if (currentPath !== path) {
        commit('auxiliary/documents/removeObject', document.fileName + '.' + document.type, { root: true })
      }
      // await dispatch('refreshDocuments')
    }
  },
  async deleteDocuments({ commit, dispatch }, payload) {
    if (!Array.isArray(payload)) payload = [payload]

    const ids = payload.map((el) => el.id)
    await dispatch('auxiliary/documents/deleteByIds', ids, { root: true })
    payload.forEach((el) => {
      commit('omitFromDocumentMap', getLodashPath(el.path))
    })
  },
  async deleteIfEmpty({ state, commit, dispatch }, payload) {
    try {
      const { status } = await dispatch('auxiliary/documents/deleteIfEmpty', payload, { root: true })
      if (status === 200) {
        // Delete was successful
        commit('omitFromDocumentMap', getLodashPath(payload))
        return true
      } else {
        // unsuccessful
        return false
      }
    } catch (e) {
      console.warn('deleteIfEmpty fileManager failed', e)
      return false
    }
  },
  async forceDelete({ state, commit, dispatch }, payload) {
    try {
      const { status } = await dispatch('auxiliary/documents/forceDelete', payload, { root: true })
      if (status === 200) {
        // Delete was successful
        await dispatch('refreshDocuments')
        return true
      } else {
        // unsuccessful
        return false
      }
    } catch (e) {
      console.warn('forceDelete fileManager failed', e)
      return false
    }
  },
  async onStompUpdate({ state, rootGetters, dispatch }, { stompMessage }) {
    let rootPrefix = rootGetters['auxiliary/documents/getRootPrefix']
    if (rootPrefix != '') rootPrefix = `.${rootPrefix}/`
    const path = rootPrefix + state.currentPath

    if (stompMessage.eventName == 'pathUpdate') {
      const { affectedPaths } = stompMessage.data
      if (!affectedPaths.includes(path)) return
      // We refresh the documents because the folder view has changed by another party
      await dispatch('refreshDocuments')
    }
  },
}

/**
 * Given a file path, return the lodash path so we can access it inside a map/object
 */
function getLodashPath(path) {
  let str = ''
  if (Array.isArray(path)) {
    const { length } = path

    path
      .filter((el) => !!el)
      .forEach((el, i) => {
        el = el.replace(/"/g, '\\"')
        if (el.includes('.')) str += `["${el}"]`
        else if (i === length - 1) str += '.' + el
        else if (i !== 0) str += '.' + el + '/'
        else str += el + '/'
      })
  } else if (typeof path === 'string') {
    const { length } = path.split('/')

    path
      .split('/')
      .filter((el) => !!el)
      .forEach((el, i) => {
        el = el.replace(/"/g, '\\"')
        if (el.includes('.')) str += `["${el}"]`
        else if (i === length - 1) str += '.' + el
        else if (i !== 0) str += '.' + el + '/'
        else str += el + '/'
      })
  }

  return str
}
