
import storeMixin from '@/mixins/storeMixin'
import configMixin from '~/mixins/configMixin.js'
import statusMixin from '~/mixins/statusMixin.js'

export default {
  mixins: [storeMixin, configMixin, statusMixin],
  props: {
    headerBar: {
      type: Boolean,
      default: false,
    },
    basic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      recent: [],
      isFocused: false,
      btnSearchFocused: false,
      btnMenuOpen: false,
      filterMenu: false,
      overflowLabels: [],
      atlasSearchResults: [],
    }
  },
  computed: {
    allGroups() {
      let groups = this.$store.getters[`modules/entityGroups/getItems`]
      if (!groups) return []
      return groups
        .filter((el) => !this.recentSearches.find((recent) => recent.id === el.id))
        .map((el) => {
          return { id: el.id, name: el?.summary?.name }
        })
    },
    isEditMode() {
      const module = this.$route?.name?.split('-')[0]
      return module ? this.$store.state?.modules[module]?.editMode : false
    },
    disableNav() {
      const inEdit = this.$db.isAppInEdit(this.$utils.getRouteParams(this.$route.path)[0], this.$utils.getRouteParams(this.$route.path)[1])
      return this.isEditMode || inEdit
    },
    recentSearches: {
      get() {
        // Use lodash filter to find matching objects based on IDs from the recent
        const recentGroups = _.filter(this.$store.state.modules.entityGroups.allItems, (group) => this.recent.includes(group.id) && !_.has(group, 'deleted'))
        if (Array.isArray(recentGroups) && recentGroups.length > 0) {
          return this.recent
            .map((id) => {
              const el = _.find(recentGroups, { id })
              if (!el) return null
              return { id: el.id, name: el.summary.name }
            })
            .filter((el) => !!el)
        }
        return []
      },
      set(searches) {
        // Set searches locally and in local storage to keep it consistent and for reactivity
        this.recent = searches
        localStorage.setItem('recentSearches', JSON.stringify(searches))
      },
    },
    selectedGroup: {
      get() {
        return this.$store.state.modules.entityGroups.selectedEntityGroup
      },
      set(groupId) {
        this.addToRecent(groupId)
        this.$store.dispatch(`modules/entityGroups/selectEntityGroup`, { id: groupId })
        this.filterMenu = false
      },
    },
    hasSearchInput() {
      if (this.search && this.search.trim() !== '') return true
      return false
    },
    menuOpen() {
      return this.$store.state.application.appData.showContextMenu
    },
    groups() {
      return this.$store.state.modules.entityGroups.allItems
        .map((group) => {
          return { id: group.id, name: group.summary.name, deleted: group.deleted }
        })
        .filter((group) => !group?.deleted)
    },
    items() {
      // Show recent searches if no filter or search criteria
      if (!this.hasSearchInput) return _.take(this.recentSearches, 10)

      // Search
      this.atlasSearchResults = this.atlasSearchResults.concat(this.groups.filter(({ name }) => name.toLowerCase().includes(this.search.trim().toLowerCase())))
      return _.uniq(this.atlasSearchResults)
    },
    disableNav() {
      const isEdit = _.get(this.$store.state, `modules.${this.selectedModule}.editMode`, false)
      const autosave = _.get(this.$store.getters['modules/configurationHub/getCurrentUserSettings'], 'autoSave', false)
      if (autosave) return false
      return isEdit
    },
  },
  methods: {
    isTextOverflow(event, id) {
      const { target } = event
      const isOverflow = target.offsetWidth < target.scrollWidth
      // Add to array of tooltips needing to be displayed
      if (isOverflow && !this.overflowLabels.includes(id)) this.overflowLabels.push(id)
      return isOverflow
    },
    openAdvancedSearch() {
      // Open giga search
      let props = {
        select: true,
        returnObject: true,
        multiple: false,
        callback: this.selectFromAdvanced,
        modules: ['entityGroups', 'entities'],
      }
      this.$store.commit(`application/globalSearch/openDialog`, props)
    },
    selectFromAdvanced(obj) {
      if (!obj) this.selectedGroup = null
      if (obj.id.includes('entities')) {
        // Group ID from entity
        this.selectedGroup = obj.entityGroupId
      } else {
        // Is already group
        this.selectedGroup = obj.id
      }
    },
    highlightMatch(item) {
      let groupName = item?.name
      let match = item?.match
      const length = item?.name?.length || 0
      if (this.search) {
        let regex = new RegExp(this.search, 'gi')
        if (this.search.charAt(0) === '0' && /^\d+$/.test(this.search)) {
          regex = new RegExp(this.search.substring(1), 'gi')
        }

        // Highlight matched text
        groupName = groupName.replace(regex, '<span style="color: var(--v-success-lighten3)">$&</span>')
        if (item.match) {
          match = match.replace(regex, '<span style="color: var(--v-success-lighten3)">$&</span>')
        }
      }
      groupName = `<span style="color: white">${groupName}</span>`
      if (item.match) {
        groupName += ` |  <span style="color: white">${match}</span>`
      }
      return { text: `${groupName}`, length: length }
    },
    clearSearch(menuOpen) {
      if (!menuOpen) this.search = ''
    },
    addToRecent(groupId) {
      if (groupId && Array.isArray(this.recent)) {
        // Add group to recent searches if it's not in it already
        if (!this.recent.includes(groupId)) {
          this.recent.unshift(groupId)
          // limit to 10 recents
          if (this.recent.length > 10) this.recent.pop()
          this.recentSearches = this.recent
        }
      }
    },
    removeFromRecent(groupId) {
      if (groupId) {
        _.remove(this.recent, (el) => el === groupId)
      }
    },
    openGroupInTab(group) {
      this.addToRecent(group.id)
      // Open group in a new tab with ID as the query
      let routeData = this.$router.resolve({ path: this.$route.params.module ? `/${this.$store.getters['application/appData/getAppValueFromModule'](this.$route.params.module)}` : '', query: { group: group.id } })
      window.open(routeData.href, '_blank')
    },
    openSearchMenu() {
      if (this.$refs.search) {
        this.$refs.search.focus()
        this.$refs.search.activateMenu()
      }
    },
    openNewDialog(openTab) {
      this.$store.commit(`modules/entityGroups/toggleCreateDialog`, { show: true, openTab: openTab })
    },
    getRecentSearches() {
      // Retrieve localstorage data
      let recent = []
      try {
        recent = JSON.parse(localStorage.getItem('recentSearches'))
        if (!Array.isArray(recent)) recent = []
        // Filter recent searches only show existing groups
        recent = recent.filter((id) => this.$store.state.modules.entityGroups.allItems.findIndex((group) => group.id === id && !group.deleted) >= 0)
      } catch (e) {
        console.warn('Could not parse local JSON data', e)
        recent = []
      }
      return recent
    },
    clearRecent() {
      if (this.$refs.search) {
        this.$refs.search.blur()
      }
      this.recentSearches = []
    },
  },
  mounted() {
    // Setup from localstorage data
    this.recentSearches = _.take(this.getRecentSearches(), 10)

    // Event to open group search from elsewhere
    if (this.headerBar) {
      this.$nextTick(() => {
        this.$bus.$off('openGroupSearch')
        this.$bus.$on('openGroupSearch', this.openSearchMenu)
      })
    }

    // Event handler for adding a recent search from elsewhere in the app
    this.$bus.$on('addRecentSearch', this.addToRecent)
    this.$bus.$on('removeRecentSearch', this.removeFromRecent)
  },
  watch: {
    async search(newValue) {
      if (newValue && newValue.length >= 4) {
        try {
          // Call your Spring Boot endpoint here
          let results = await this.$axios.$get(`/api/v1/search/phoneNumberSearch`, { params: { text: newValue } })

          // Transform results
          this.atlasSearchResults = results.map((result) => {
            const entityGroupId = _.get(result, 'entityGroupId')

            // Assuming you have a method getNameForEntityGroup to fetch the name for entityGroupId
            const name = this.groups.find(({ id }) => id == entityGroupId)?.name || null

            // Get the highlight with the best score
            const bestHighlight = _.maxBy(result.highlights, 'score')
            // Extract the match text based on the highlight's path
            let match = ''
            if (bestHighlight) {
              const pathParts = bestHighlight.path.split('.') // Split the path into parts
              let entityValue = _.get(result.entity, pathParts.slice(0, -1)) // Get the part before the last segment

              // Check if the entityValue is an array
              if (Array.isArray(entityValue)) {
                const field = _.last(pathParts) // Get the last part of the path (e.g., 'value')

                // Iterate over the array and find the matching value
                match = entityValue.map((item) => _.get(item, field)).join(', ') // Concatenate matches (e.g., "+61 405 767 974")
              } else {
                // If it's not an array, get the value directly
                match = _.get(result.entity, pathParts) || ''
              }
            }

            // Return the transformed result
            return {
              id: entityGroupId,
              name,
              match,
            }
          })
        } catch (error) {
          console.error('Error fetching search results:', error)
        } finally {
          // this.isLoading = false
        }
      }
    },
  },
}
