export default {
  data() {
    return {
      stagedStatus: [{ status: 'STAGED', colour: '#C7CEEA' }],
    }
  },
  computed: {
    baseStatuses() {
      return [
        { status: 'ACTIVE', colour: '#B2DDAB' },
        { status: 'INACTIVE', colour: '#F1B182' },
        { status: 'PENDING', colour: '#AACDE6' },
      ].map(this.mapTextValue)
    },
    /**
     * Statuses across Organisation Tools: Tasks, Workflows, Kanbans
     */
    statuses() {
      return [
        { status: 'NOT_STARTED', colour: '#B2CDE6' },
        { status: 'IN_PROGRESS', colour: '#F1D982' },
        { status: 'COMPLETED', colour: '#B2DDAB' },
        { status: 'TERMINATED', colour: '#F1B182' },
      ].map(this.mapTextValue)
    },
    deletedStatus() {
      return [{ status: 'DELETED', colour: '#A5A5A5' }].map(this.mapTextValue)
    },
    priorityStatus() {
      return [{ status: 'PRIORITY', colour: '#EFACAC' }].map(this.mapTextValue)
    },
    completedStatuses() {
      return ['COMPLETED', 'PAID', 'PUBLISHED']
    },
    /**
     * Specific to Requests
     */
    requestStatuses() {
      return [
        { status: 'CREATED', colour: '#B2CDE6' },
        { status: 'OPENED', colour: '#F1D982' },
        { status: 'COMPLETED', colour: '#B2DDAB' },
        { status: 'TERMINATED', colour: '#F1B182' },
      ].map(this.mapTextValue)
    },
    /**
     * Specific to Emails
     */
    fileNoteStatuses() {
      return [
        { status: 'NOT_STARTED', colour: '#B2CDE6' },
        { status: 'DRAFT', colour: '#F1D982' },
        { status: 'COMPLETED', colour: '#B2DDAB' },
        { status: 'PRIORITY', colour: '#EFACAC' },
        { status: 'TERMINATED', colour: '#F1B182' },
      ].map(this.mapTextValue)
    },
    /**
     * Specific to SharedFolders
     */
    sharedFolderStatuses() {
      return [
        { status: 'OPEN', colour: '#B2CDE6' },
        { status: 'CLOSED', colour: '#B2DDAB' },
      ]
    },
    invoiceStatuses() {
      return [
        { status: 'DRAFT', colour: '#B2CDE6' },
        { status: 'SUBMITTED', colour: '#F1D982' },
        { status: 'AUTHORISED', colour: '#F1D982' },
        { status: 'PAID', color: '#B2DDAB' },
        { status: 'VOIDED', colour: '#F1B182' },
      ].map(this.mapTextValue)
    },
    /**
     * @returns stores/modules whose statuses are read-only
     */
    readonlyModules() {
      return ['requests']
    },
  },
  methods: {
    /**
     * @returns true if the module's statuses can be changed, false otherwise
     */
    canModifyStatus(modul = null) {
      if (modul == null) modul = this.$route.name.split('-')[0]
      return !this.readonlyModules.includes(modul)
    },
    /**
     * @returns statuses based on $route by default, or based on the provided modules
     */
    statusesForModule(...modules) {
      let filtered = modules.filter(el => el) // Remove nulls
      const _modules = filtered.length == 0 ? [this.$route.name.split('-')[0]] : filtered
      let statuses = []
      for (const mod of _modules) {
        switch (mod) {
          case 'clientPortal':
            statuses.push(...this.requestStatuses.concat(this.statuses))
            break
          case 'requests':
            statuses.push(...this.requestStatuses)
            break
          case 'sharedFolders':
            statuses.push(...this.sharedFolderStatuses)
            break
          case 'emails':
            statuses.push(...this.statuses)
            break
          case 'fileNotes':
            statuses.push(...this.fileNoteStatuses)
            break
          case 'tasks':
            statuses.push(...this.stagedStatus)
          case 'workflows':
          case 'kanbans':
            statuses.push(...this.priorityStatus)
            statuses.push(...this.statuses)
            break
          case 'invoices':
            statuses.push(...this.invoiceStatuses)
            break
          default:
            statuses.push(...this.baseStatuses)
            break
        }
      }
      return _.uniqBy(statuses, 'status')
    },
    filterOutStatus(...statuses) {
      return (item) => {
        return !statuses.includes(item?.status)
      }
    },
    mapTextValue(obj) {
      return {
        ...obj,
        value: obj.status,
        text: this.$utils.capitaliseWords(obj.status),
        color: obj?.colour,
      }
    },
    getStatusList() {
      let list = _.cloneDeep(this.statuses)
      list.push(...this.requestStatuses)
      list.push(...this.priorityStatus)
      list.push(...this.sharedFolderStatuses)
      list.push({ status: 'NONE', colour: '#E0E0E0' })
      return list
    },
    colourFromStatus(status) {
      const found = this.statuses
        .concat(this.requestStatuses)
        .concat(this.baseStatuses)
        .concat(this.deletedStatus)
        .concat(this.priorityStatus)
        .concat(this.sharedFolderStatuses)
        .concat(this.fileNoteStatuses)
        .concat(this.invoiceStatuses)
        .concat(this.stagedStatus)
        .find((el) => el.status === status)
      return found?.colour ?? 'transparent'
    },
  },
}
