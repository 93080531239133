function getRequestColour(requestType) {
  switch (requestType) {
    case 'GET':
      return '#61AFFE'
    case 'POST':
      return '#49CC90'
    case 'PUT':
      return '#FCA130'
    case 'PATCH':
      return '#50E3C2'
    case 'DELETE':
      return '#F93E3E'
  }
  return null
}

export default ({ $axios, $sentry, $config, store, $bus, $faro, route }) => {
  const authServerDomain = $config.authDomain

  // If these strings are found in the request URL it will not show a notification
  let urlNotificationBlacklist = ['pagination', 'paginate', 'notification', 'segment', 'document', 'search', 'tasks/search', '/filterOptions', '/filter', 'workflow/search', 'element/isUniqueName', 'requests/section', 'kanbans/progress', 'kanbans/lookup']
  let postUrlBlacklist = [authServerDomain, 'deletes', 'archives', 'update', 'findRange', 'range', 'fetchIds', 'google/auth', 'services.syncfusion.com', 'countEventsInRange', 'week/count' ]

  // put an error flag for all 4xx/5xx responses
  $axios.interceptors.response.use((cfg) => {
    if (String(cfg?.status).startsWith('2') || String(cfg?.status).startsWith('3') || String(cfg?.status).startsWith('1')) cfg.error = false
    else cfg.error = true
    return cfg
  })

  $axios.onRequest((config) => {
    config.crossDomain = true
    //PRINT EVEN IN LIVE
    if (config.url.includes('auth0|')) {
      config.url = encodeURI(config.url)
    }

    // Making request to documents - set the selectedEntityGroup param for every request
    if (config.url.startsWith('/api/v1/document') && !config.url.startsWith('/api/v1/documentEngine') && !config.url.startsWith('/api/v1/documentSection')) {
      const selectedEntityGroup = store.getters[`auxiliary/documents/getRootPrefix`]
      if (!selectedEntityGroup) config.params = { ...config.params }
      else config.params = { ...config.params, selectedEntityGroup: selectedEntityGroup }
    }

    if (!config.url.includes(authServerDomain)) {
      let transactionId = store.getters['application/webStomp/getTransactionId']
      config.headers.common['webstomp-transaction'] = transactionId
    }

    if (config.method.toUpperCase() === 'POST' && !urlNotificationBlacklist.concat(postUrlBlacklist).some((v) => config.url.includes(v))) {
      store.commit('application/snack/set', { type: 'success', message: `Creating...` }) // If posts aren't for delete/archive show this message
    }

    // Print if not on production
    if ($config.dev !== 'production') console.log(`%cRequest:  ${config.method.toUpperCase()} ${config.url}`, `color: ${getRequestColour(config.method.toUpperCase())}`, config.data ? config.data : '')

    // Add to pending reuqests
    store.commit(`application/appData/addAxiosRequest`, config.url)
  })

  $axios.onResponse(({ config, data, status, headers }) => {
    if (!config.url.includes(authServerDomain)) {
      if (config.method.toUpperCase() === 'PUT' && config.url.includes('restore')) {
        store.commit('application/snack/reset') // Reset snackbar on restore to avoid archiving timeout occuring after restoring
      } else if (config.method.toUpperCase() === 'POST' && !urlNotificationBlacklist.concat(postUrlBlacklist).some((v) => config.url.includes(v))) {
        store.commit('application/snack/set', { type: 'success', message: `Successfully created.` }) // If posts aren't for delete/archive show this message
      }
    }

    if ($config.dev !== 'production' && data) {
      if (headers && headers['content-type'] && headers['content-type'] === 'application/json') {
        // Print if not on production
        console.log(`%cResponse: ${config.method.toUpperCase()} ${config.url}`, `color: ${getRequestColour(config.method.toUpperCase())}`, data ? data : '')
      }
    }

    // Remove from pending requests
    store.commit(`application/appData/removeAxiosRequest`, config.url)

    // // no content custom interceptors
    // if(status == 204) return Promise.resolve(null);
    // if(status == 224) return Promise.resolve([]);
  })

  $axios.onError(async (error) => {
    // Remove from pending requests
    store.commit(`application/appData/removeAxiosRequest`, _.get(error, 'response.config.url'))

    const code = parseInt(_.get(error, 'response.status'))
    const data = _.get(error, 'response.data')
    if (!code && typeof _.get(error, 'message') === 'string' && _.get(error, 'message').toLowerCase().includes('cancel')) return // Do nothing if the error is from a cancel request
    let errorMsg = ''

    switch (code) {
      case 400:
        errorMsg = `Malformed request syntax!`
        break
      case 401:
      case 403:
        errorMsg = `Not Authorized: Sorry, you can't access this!`
        break
      case 404:
        errorMsg = `Not Found: We couldn't find what you're looking for. Please refresh and try again, or contact the support team.`
        break
      case 415:
      case 500:
        errorMsg = 'Server Error: Please contact the support team.'
        break
      case 569:
        errorMsg = 'Critical Server Error: You will be logged out. Please try again, or contact the support team.'
        break
      default:
        errorMsg = error
    }

    errorMsg = !_.isNil(data) && typeof data === 'string' ? data : errorMsg

    // Print if not on production
    if ($config.dev !== 'production') {
      console.error(errorMsg, code)
    }

    const errorDetails = {
      name: 'Error',
      message: errorMsg,
      path: route?.path,
      statusCode: code,
    }

    $faro.api.pushError(new Error(JSON.stringify(errorDetails)), {
      skipDedupe: false,
      type: 'Error',
      context: {
        environment: process.env.NODE_ENV,
        application: 'Elan Frontend',
      },
    })

    if (isNaN(code) && errorMsg.toString().includes('ExpiredAuthSessionError')) {
      // Refresh tokens
      await store.dispatch('auxiliary/user/logout')
      return Promise.reject(error)
    }
    if (code !== 570 && code !== 469) $sentry.captureException(new Error(error))

    /**
     * 469 = trial expired
     * 569 = logout
     * 570 = session kicked
     */

    if (code === 569) {
      sessionStorage.setItem('auth0Message', error?.response?.data)
      store.dispatch('auxiliary/user/logout', false)
    } else if (code === 570) {
      // warn the user before logging out (session kicked)
      sessionStorage.setItem('auth0Message', 'You were logged out because another session has logged in with your credentials.')
      store.dispatch('auxiliary/user/logout', true)
    } else if (code === 469) {
      $bus?.$emit('trialExpired')
    }

    return Promise.reject(error)
  })
}
